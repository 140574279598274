import axios from "axios";
import Utility from "../../../shared/utility";
import { store } from "../../../state-management/state-mgmt";
export default {
  postBusinessData(obj) {
    let call = "post";
    return this.getData("/co/business_getbyId", call, obj);
  },

  getInboundData(call, pass, userId, salesOrderId, messageId) {
    return this.getData(`/so/so_order_api_in_out?Pass=${pass}&UserId=${userId}&so_key=${salesOrderId}&msg_id=${messageId}`, call);
  },

  getReturnAddData(call, userId, proj_key, searchTest, bu_id, obj, showMessage, disableLoader, pass) {
    return this.getData(
      `/so/so_rtn_address?UserId=${userId}&proj_key=${proj_key}&SearchStr=${searchTest}&bu_key=${bu_id}&Pass=${pass}`,
      call,
      obj,
      showMessage,
      disableLoader
    );
  },

  postOrderTypeData(call, obj) {
    return this.getData("/so/so_order_types", call, obj);
  },

  getWarrentyData(call, userId) {
    return this.getData(`/so/so_warrantytype?UserId=${userId}`, call);
  },

  countryList(call) {
    return this.getData("/cp/country_state_details", call);
  },

  getOrderTypeData(call, userId, proj_key, so_id) {
    return this.getData(`/so/so_linetypelist?UserId=${userId}&ProjKey=${proj_key}&so_id=${so_id}`, call);
  },

  getSalesOrderDetails(call, userId, salesOrder) {
    return this.getData(`/so/so_get?UserId=${userId}&so_id=${salesOrder}`, call);
  },

  postcarrierServiceLvlData(obj) {
    let call = "post";
    return this.getData("/so/so_service_levels", call, obj);
  },
  postCustomerData(call, obj, showMessage, disableLoader) {
    return this.getData("/co/customer_data", call, obj, showMessage, disableLoader);
  },

  postShipSearch(call, obj, showMessage, disableLoader) {
    return this.getData("/so/so_shippart_search", call, obj, showMessage, disableLoader);
  },

  postShipWareData(call, obj) {
    return this.getData("/so/so_ppid_validate", call, obj);
  },

  getLineItemData(call, userId, line_id) {
    return this.getData(`/so/so_lineitem_details?UserId=${userId}&line_id=${line_id}`, call);
  },

  postBuildData(call, obj, showMessage, disableLoader) {
    return this.getData("/so/so_buildpart_search", call, obj, showMessage, disableLoader);
  },

  postReturnSearch(call, obj, showMessage, disableLoader) {
    return this.getData("/so/so_returnpart_search", call, obj, showMessage, disableLoader);
  },

  addBookMark(call, userId, so_id, showMessage) {
    return this.getData(`/so/so_order_bookmark?UserId=${userId}&so_id=${so_id}&Pass=2`, call, "", showMessage);
  },
  postSoUpdate(call, obj, showMessage) {
    return this.getData("/so/so_order_update", call, obj, showMessage);
  },

  postSoDelete(call, obj, showMessage) {
    return this.getData("/so/so_order_actions_del_hld", call, obj, showMessage);
  },
  postSoCancel(call, obj, showMessage) {
    return this.getData("/so/so_order_cancel", call, obj, showMessage);
  },

  getSalesOrderPickedData(call, so_id, line_id, user_id) {
    return this.getData(`/so/so_pick_details?so_key=${so_id}&line_item=${line_id}&UserId=${user_id}`, call, "");
  },

  getSalesOrderReceivedData(call, so_id, line_id, user_id) {
    return this.getData(`/so/so_receipt_details?so_key=${so_id}&line_item=${line_id}&UserId=${user_id}`, call, "");
  },

  getSalesOrderShippedData(call, so_id, line_id, user_id) {
    return this.getData(`/so/so_shipment_details?so_key=${so_id}&line_item=${line_id}&UserId=${user_id}`, call, "");
  },

  async getData(url, call, obj, showMessage, disableLoader) {
    if (!disableLoader) {
      var LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
    } else {
      LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    }
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
      
        if (error.response) {
          if (error.response.data.message !== "No data found") {
            let Alert = {
              type: "error",
              isShow: true,
              message: error.response.data.message,
            };
            store.commit("ConfigModule/Alert", Alert);
            LoaderDialog.visible = false;
            store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          }
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error?.response?.data?.message ? error.response.data.message: error.response.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
        return JSON.parse('{"message":"NA"}');
      });
    }
    if (res?.data?.body?.message != undefined || res?.data?.body?.message != null) {
      if (!res?.data?.body?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res.data.body.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.body.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: JSON.parse(res?.data?.body?.message).Result[0].Message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        return JSON.parse(res?.data?.body?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    } else {
      if (!res?.data?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message:res?.data?.message? Utility.apiError(res?.data?.message):"No Data Available",
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res?.data?.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse('{"message":"NA"}');
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        return JSON.parse(res?.data?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return JSON.parse('{"message":"NA"}');
      }
    }
  },
};
