<template>
  <div>
    <v-row justify="center pt-3">
      <v-col cols="12" sm="12" md="12" lg="12" class="pa-2 pb-0">
        <v-form ref="custValidateForm" lazy-validation @submit.prevent="submit">
          <v-row class="pb-3 px-5 py-0">
            <v-col
              v-if="actionSetter('DefineCustomer') && hideCustNum && this.compName !== 'AddReturnTo' && this.compName !== 'EditReturnTo'"
              cols="12" sm="12" md="12" lg="4">
              <!-- Auto-Generate Customer -->
              <v-checkbox @click="onChangeCust();" :disabled="disableValidateBtn" v-model="showCustomer"
                label="Define Customer Number" class="pr-1  pb-3 ma-0 d-inline-block" color="primary"
                hide-details></v-checkbox>
            </v-col>
            <v-col v-if="showCustomer && actionSetter('DefineCustomer')" cols="12" sm="12" md="12" lg="4">
              <v-text-field persistent-placeholder :disabled="disableValidateBtn" :rules="max30Rule" :counter="30"
                v-model="customerNum" class="ma-0" label="Customer Number" outlined @input="checkCustNum(customerNum)"
                @blur="disableTextbox()" @click:clear="outLineText = ''" :color="outLineText" clearable dense>
              </v-text-field>
            </v-col>
            <v-col v-if="!hideCustNum" cols="12" sm="12" md="12" lg="4" class=" py-0 pb-5">
              <v-template class="ma-0 pa-0 secondary--text">Customer Number</v-template> <br />
              <v-template class="ma-0 pa-0 f-15 f-bold">{{
                editJSON.sh_custno }}</v-template>
            </v-col>
          </v-row>
        </v-form>
        <!-- row 2 -->
        <v-form ref="AddressForm" lazy-validation @submit.prevent="submit">
          <v-row v-if="showAddFreature">
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field persistent-placeholder :disabled="disableValidate" v-model="title" class="ma-0"
                label="Title" outlined clearable dense @input="title = title?.toUpperCase()">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field :rules="required" persistent-placeholder :disabled="disableValidate" v-model="lastName"
                class="ma-0" label="Name" outlined @input="lastName = lastName?.toUpperCase()" clearable dense>
              </v-text-field>
            </v-col>
            <v-col v-if="!showAddFreature" cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field :disabled="disableValidate" persistent-placeholder v-model="store" class="ma-0"
                label="Store/Site/Id" outlined clearable @input="store = store?.toUpperCase()" dense>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field :rules="required" :disabled="disableValidate" persistent-placeholder v-model="company"
                class="ma-0" label="Company Name" outlined clearable dense @input="company = company?.toUpperCase()">
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Row 3 -->
          <v-row>
            <!-- Adddress 1 -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field :rules="required" :disabled="disableValidate" persistent-placeholder v-model="address1"
                class="ma-0" label="Address1" @input="address1 = address1?.toUpperCase()" outlined clearable dense>
              </v-text-field>
            </v-col>
            <!-- Address 2 -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field :disabled="disableValidate" v-model="address2" persistent-placeholder class="ma-0"
                label="Address2" outlined clearable @input="address2 = address2?.toUpperCase()" dense>
              </v-text-field>
            </v-col>
            <!-- City -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field :rules="required" :disabled="disableValidate" persistent-placeholder v-model="city"
                label="City" class="ma-0" outlined clearable @input="city = city?.toUpperCase()" dense>
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Row 4 -->
          <v-row>
            <!-- State -->
            <v-col v-if="showStateDrop" cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-autocomplete :rules="required" :disabled="disableValidate" persistent-placeholder
                v-model="selectedState" class="ma-0" :items="stateData" item-text="States" label="State" outlined
                item-value="StateKey" @input="company = company.toUpperCase()" dense>
              </v-autocomplete>
            </v-col>
            <!-- State if dropdown is empty -->
            <v-col v-else cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field persistent-placeholder :rules="stateRule" :disabled="disableValidate"
                v-model="selectedState" class="ma-0" label="State" name="state" outlined clearable dense
                @input="selectedState = selectedState?.toUpperCase()">
              </v-text-field>
            </v-col>
            <!-- Postal -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field color="primarytext" v-model="postal" label="Postal Code" name="postal"
                outlined clearable dense v-if="selectedCountry!=='USA'" maxlength="20"
                :disabled="disableValidate"
                :rules="postalCodeNonUS" persistent-placeholder @input="postal=postal.toUpperCase()">
              </v-text-field>
              <v-text-field color="primarytext" v-model="postal" label="Postal Code" name="postal"
                outlined clearable dense v-else maxlength="10"
                :disabled="disableValidate"
                :rules="zipcode" persistent-placeholder @keypress="postalCodeFormat($event,postal)">
              </v-text-field>
            </v-col>
            <!-- COuntry -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-autocomplete persistent-placeholder :rules="required" :disabled="disableValidate" attach outlined
                v-model="selectedCountry" :items="countryData" item-text="Country" item-value="CountryKey"
                label="Country" dense class="ma-0" @change="countrySelected()">
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- Row 5 -->
          <v-row>
            <!-- Phone 1 -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field persistent-placeholder :rules="phone?.length > 0 || cell?.length > 0 ? [] : required"
                :disabled="disableValidate" v-model="phone" class="ma-0" label="Phone" outlined clearable dense>
              </v-text-field>
            </v-col>
            <!-- Phone 2 -->
            <v-col v-if="showAddFreature" cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field persistent-placeholder :disabled="disableValidate" v-model="phone2" class="ma-0"
                label="Phone2" outlined clearable dense>
              </v-text-field>
            </v-col>
            <!-- Fax -->
            <v-col v-if="showAddFreature" cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field persistent-placeholder :disabled="disableValidate" v-model="fax" class="ma-0" label="Fax"
                outlined clearable dense>
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Row 6 -->
          <v-row>
            <v-col v-if="showAddFreature" cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field persistent-placeholder :rules="phone?.length > 0 || cell?.length > 0 ? [] : required"
                :disabled="disableValidate" v-model="cell" class="ma-0" label="Cell" outlined clearable dense>
              </v-text-field>
            </v-col>
            <!-- Email -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field persistent-placeholder :rules="email ? emailRule : []" :disabled="disableValidate"
                v-model="email" class="ma-0" label="Email" outlined clearable dense>
              </v-text-field>
            </v-col>
            <v-col v-if="!showAddFreature" cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-text-field @input="Name = Name?.toUpperCase()" persistent-placeholder :disabled="disableValidate"
                v-model="Name" class="ma-0" label="Name" outlined clearable dense>
              </v-text-field>
            </v-col>
            <!-- submit -->
            <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
              <v-btn @click="submitAddress(compName)" color="primarytext" outlined class="mx-auto btn-style"
                elevation="1">
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import salesService from './js/sales-order-service.js';
import EncryptUtility from '../utility/js/encrypt-utility';
import commonApiService from '../common/js/common-api-service';
import Utility from "../../shared/utility.js"
export default {
  name: 'Address',
  props: {
    compName: String,
    proj_key: String,
    editJSON: Object,
    actionList: Array,
  },
  data() {
    return {
      max30Rule: [
        (v) => !!v || 'Field is required',
        (v) =>
          (v || '').length <= 30 ||
          'Max Length must be 30 characters or less',
      ],
      stateRule: [(v) => !!v || 'Field is required',
      (v) =>
        /^[a-zA-Z0-9 ]+$/.test(v) ||
        'special character are not allowed'
      ],
      required: [(v) => !!v || 'Field is required'],
      emailRule: [(v) =>
        /.+@.+\..+/.test(v) ||
        'Enter valid Email'],
      sameAsCust: false,
      sameAsReturn: false,
      outLineText: "",
      sameAsShip: false,
      showAddFreature: false,
      disableValidate: false,
      disableValidateBtn: false,
      showCustomer: false,
      customerNum: "",
      search: "",
      store: "",
      fax: "",
      phone2: "",
      Name: "",
      phone: "",
      email: "",
      company: "",
      selectedCountry: "",
      hideCustNum: true,
      selectedState: "",
      lastName: "",
      firstName: "",
      title: "",
      address2: "",
      userId: EncryptUtility.localStorageDecrypt('userID'),
      address1: "",
      countryData: [],
      stateData: [],
      city: "",
      postal: "",
      showStateDrop: false,
      zipcode: [(v) => /^\d{5}(-\d{4})?$/.test(v) || "Enter valid Postal"],
      postalCodeNonUS: [(v) => /^[a-zA-Z0-9 ]+$/.test(v) || "Invalid Postal Code"],
    }
  },
  async created() {
    this.showAddFreature = this.compName == "AddCustomer" || this.compName == "AddShipTo" ||
      this.compName == "AddReturnTo" || this.compName == "EditShipTo" || this.compName == "EditReturnTo" ? true : false
    this.route = this.$route.path.replace('/', '');
    let data = await commonApiService.countryListNew(this.userId, "", 1, "get");
    this.countryData = data.CountryInfo;
    if (!this.compName == "EditShipTo") {
      this.showCustomer = true;
      this.disableValidate = false;
    }
    else if (this.compName == "AddReturnTo" || this.compName == "AddCustomer" || this.compName == "AddShipTo") {
      this.selectedCountry = "USA";
      this.countrySelected();
    }
  },
  mounted() {
    this.$refs.AddressForm.reset()
    this.$refs.custValidateForm.reset()
    if (this.compName == "EditShipTo") {
      this.selectedCountry = this.editJSON.sh_country
      this.countrySelected();
      this.hideCustNum = false
      this.showCustomer = false
      this.disableValidate = false
      this.disableValidateBtn = true
      this.title = this.editJSON.sh_title
      this.customerNum = this.editJSON.sh_custno
      this.lastName = this.editJSON.sh_last
      this.company = this.editJSON.sh_company
      this.address1 = this.editJSON.sh_address1
      this.address2 = this.editJSON.sh_address2
      this.city = this.editJSON.sh_city
      this.postal = this.editJSON.sh_zip
      this.selectedState = this.editJSON.sh_state
      this.phone = this.editJSON.sh_phone1
      this.phone2 = this.editJSON.sh_phone2
      this.fax = this.editJSON.sh_fax
      this.cell = this.editJSON.sh_cell
      this.email = this.editJSON.sh_email
    }
    else if (this.compName == "EditReturnTo") {
      this.selectedCountry = this.editJSON.rt_country
      this.countrySelected();
      this.disableValidate = false
      this.disableValidateBtn = true
      this.title = this.editJSON.rt_title
      this.customerNum = this.editJSON.rt_custno
      this.lastName = this.editJSON.rt_last
      this.company = this.editJSON.rt_company
      this.address1 = this.editJSON.rt_address1
      this.address2 = this.editJSON.rt_address2
      this.city = this.editJSON.rt_city
      this.postal = this.editJSON.rt_zip
      this.selectedState = this.editJSON.rt_state
      this.phone = this.editJSON.rt_phone1
      this.phone2 = this.editJSON.rt_phone2
      this.fax = this.editJSON.rt_fax
      this.cell = this.editJSON.rt_cell
      this.email = this.editJSON.rt_email
    }
  },
  methods: {
    actionSetter(action) {
      if (
        this.actionList !== undefined &&
        this.actionList !== null &&
        this.actionList !== ""
      ) {
        let filter = this.actionList.filter(
          (entry) => entry.SystemName === action
        );
        return filter !== undefined && filter !== null && filter.length == 1
          ? true
          : false;
      } else return false;
    },
    disableTextbox() {
      if (this.outLineText == "success" && this.customerNum.length > 2) {
        this.disableValidateBtn = true
      }
    },
    checkCustNum(text) {
      this.customerNum = text?.toUpperCase()
      if (text?.length > 2) {
        this.onClickValidate()
      }
      else {
        this.disableValidate = true;
        this.outLineText == ""
      }
    },
    onChangeCust() {
      if (this.showCustomer) {
        this.customerNum = "";
        this.disableValidate = true;
        this.showCustomer = true
      }
      else {
        this.disableValidate = false;
        this.showCustomer = false;
      }
    },
    // To get the data of Dropdowns
    async submitAddress(name) {
      if (this.$refs.AddressForm.validate()) {
        let custObj = {
          auto_cust_num: this.showCustomer ? 0 : 1,
          cust_id: 0,
          proj_key: this.proj_key,
          title: this.title,
          first: "",
          custno: this.customerNum,
          last: this.lastName,
          company: this.company,
          address1: this.address1,
          address2: this.address2,
          city: this.city,
          state: this.selectedState,
          zip: this.postal,
          country: this.selectedCountry,
          phone1: this.phone,
          phone2: this.phone2,
          cell: this.cell,
          email: this.email,
          UserId: this.userId,
          fax: this.fax
        }
        if (name == "AddCustomer") {
          this.$emit('updatedAPI', custObj, "customer");
        }
        else if (name == "AddShipTo") {
          this.$emit('updatedAPI', custObj, "ship");
        }
        else if (name == "AddReturnTo") {
          this.$emit('updatedAPI', custObj, "return");
        }
        else if (name == "EditShipTo") {
          this.$emit('updatedAPI', custObj, "shipEdit");
        }
        else if (name == "EditReturnTo") {
          this.$emit('updatedAPI', custObj, "returnEdit");
        }
        this.$refs.AddressForm.resetValidation()
        this.$refs.AddressForm.reset()
        this.$refs.custValidateForm.resetValidation()
        this.$refs.custValidateForm.reset()
      }
    },
    // to validate customer date
    async onClickValidate() {
      if (this.compName == "AddCustomer") {
        if (this.$refs.custValidateForm.validate()) {
          let validateData = await salesService.getValidateCustomer("get", this.userId, this.customerNum, this.proj_key, true)

          if (validateData[0]?.Message) {
            this.disableValidate = false;
            this.outLineText = "success"
          }
          else {
            this.outLineText = "error";
            this.disableValidate = true;
          }
        }
      }
      else if (this.compName == "AddShipTo") {
        if (this.$refs.custValidateForm.validate()) {
          let validateData = await salesService.getValidateCustomer("get", this.userId, this.customerNum, this.proj_key, true)
          if (validateData[0]?.Message) {
            this.disableValidate = false;
            this.outLineText = "success"
          }
          else {
            this.outLineText = "error";
            this.disableValidate = true;
          }
        }
      }
      else {
        if (this.$refs.custValidateForm.validate()) {
          let validateData = await salesService.getValidateReturn("get", this.userId, this.customerNum, this.proj_key)
          if (validateData[0]?.Message)
            this.disableValidate = false
        }
      }
    },
    //Country Selected
    async countrySelected() {
      this.selectedState = "";
      this.postal = "";
      this.$refs.AddressForm.resetValidation();
      let data1 = await commonApiService.countryListNew(this.userId, this.selectedCountry, 2, "get");
      this.stateData = data1.StateInfo;
      if (this.stateData.length !== 0) {
        this.showStateDrop = true;
      } else {
        this.showStateDrop = false;
      }
    },
    //To check if its a number of not
    postalCodeFormat(event, num) {
      this.postal = Utility.formatPostalCode(event, num);
    },
  }
}
</script>